import ESdata from "./data/es.json";
import ENdata from "./data/en.json";
import DEdata from "./data/de.json";
import { Localization } from "../entities/Localization";

export const dictionaryList: Record<string, Localization> = {
  es: Object.assign({}, ESdata),
  en: Object.assign({}, ENdata),
  de: Object.assign({}, DEdata),
};

export const languageOptions = ["es", "en", "de"];
