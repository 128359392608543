import React, { useState, createContext, useContext } from "react";

import { languageOptions, dictionaryList } from "../languages";

// create the language context with default selected language
export const LanguageContext = createContext({
  language: languageOptions[0],
  dictionary: dictionaryList[languageOptions[0]],
  setLanguage: (selectedLanguage: string) => {},
});

// it provides the language context to app
export function LanguageProvider(props: any) {
  const languageContext = useContext(LanguageContext);
  const [language, setLanguage] = useState(languageContext.language);
  const [dictionary, setDictionary] = useState(languageContext.dictionary);

  const provider = {
    language,
    dictionary,
    setLanguage: (selectedLanguage: string) => {
      setLanguage(selectedLanguage);
      setDictionary(dictionaryList[selectedLanguage]);
    },
  };

  return (
    <LanguageContext.Provider value={provider}>
      {props.children}
    </LanguageContext.Provider>
  );
}

// // get text according to id & current language
// export function Text(props: string) {
//   const languageContext = useContext(LanguageContext);

//   return languageContext.dictionary[props];
// }
